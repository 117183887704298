import { Injectable } from '@angular/core';
import { of as observableOf, Observable } from 'rxjs';
import { StatsBarData } from '../data/stats-bar';

@Injectable()
export class StatsBarService extends StatsBarData {

  private statsBarData: number[] = [11, 9, 3, 8, 5, 2, 1, 0, 7, 1, 11, 1];


  getStatsBarData(): Observable<number[]> {
    return observableOf(this.statsBarData);
  }
}
