export class Constants {
    public static TOKEN = "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJsaW9udXQucHJvajJAZ21haWwuY29tIiwiaWF0IjoxNjI4MzMxNzg2LCJleHAiOjE2Mjg2OTE3ODZ9.QB40lBLt6xK5dchSOdKKld3nsCnysl-hPssWmBswCrRFW6eOhkFMnbt-sNqMI0mi2za3dLuCSUyeS8H_yCUusA";
    //public static HOST = "http://localhost:8090";
    // public static HOST = "http://192.168.15.106:8090";
    // public static HOST = "http://spartan-srv.local.com:8090";
    public static HOST = "https://application.spartandetector.ro";

    public static API_SITES_GET_ALL = Constants.HOST + "/api/sites/";
    public static API_ADD_SITE_CREDENTIAL = Constants.HOST + "/api/sites/createSiteLogin";
    public static API_DEL_SITE_CREDENTIAL = Constants.HOST + "/api/burp/site/deleteCredential/"
    public static API_UPDATE_SITE = Constants.HOST + "/api/burp/site/updateSiteScope"
    public static API_ADD_SITE = Constants.HOST + "/api/burp/site/add";
    public static API_SITES = Constants.HOST + "/api/sites/";
    public static API_DEL_SITE = Constants.HOST + "/api/burp/site/";

    public static API_HOSTS_GET_ALL = Constants.HOST + "/api/hosts/";
    public static API_HOSTS = Constants.HOST + "/api/hosts/";
    public static API_HOST_DETAILS = Constants.HOST + "/api/hosts/";
    public static API_HOST_CREDENTIALS = Constants.HOST + "/api/hosts/";


    public static API_TARGETS = Constants.HOST + "/api/targets/";


    public static API_SCANS = Constants.HOST + "/api/scans/";

    public static API_SITE_SCAN_CONFIGS = Constants.HOST +"/api/sites/scan_configurations";
    public static API_HOST_SCAN_CONFIGS = Constants.HOST + "/api/hosts/templates";

    public static API_URL_USERS = Constants.HOST + "/api/users/";
    public static API_PATCH_USER = Constants.HOST + "/api/users/id/";
    public static API_DELETE_USER = Constants.HOST + "/api/users/id/";
    public static API_URL_USER_REGISTRATION = Constants.HOST + "/api/users/free/";
    public static API_RESET_USER_PASSWORD = Constants.HOST + "/api/users/resetpassword/";

    public static API_STATISTICS = Constants.HOST + "/api/statistics/";

    public static API_STATISTICS_DASHBOARD = Constants.HOST + "/api/dashboard/";
    
    public static API_SETTINGS_BLDOMAINS = Constants.HOST + "/api/blacklist/domains/";
    public static API_SETTINGS_BLIPRANGES = Constants.HOST + "/api/blacklist/ip-ranges/";

    public static API_SITE_SCAN_REPORT = Constants.HOST + "/api/sites/report/";
    public static API_TMP_SITE_SCAN_REPORT = Constants.HOST + "/api/sites/reportForVisitor/";

    public static API_TMP_HOST_SCAN_REPORT = Constants.HOST + "/api/hosts/reportForVisitor/";

    public static API_TMP_SCAN_SCAN_REPORT = Constants.HOST + "/api/scans/reportForVisitor/";

    public static API_HOST_SCAN_REPORT = Constants.API_HOSTS;
    public static API_SCAN_REPORT = Constants.HOST + "/api/scans/report/";


    public static API_TARGET_SCANS_HISTORY = Constants.HOST + "/api/scans/for_target/";

    //TO DO
    public static API_TARGET_SCAN_REPORT = Constants.HOST + "/api/scans/report/";
    //   /api/scans/{{scanIdMAre}}/report

    public static API_EVENTS_DASHBOARD = Constants.HOST + "/api/events/dashboard";

    public static API_DOWNLOAD_SITE_REPORT = Constants.HOST + "/api/sites/report/";
    public static API_SEND_SITE_REPORT = Constants.API_DOWNLOAD_SITE_REPORT;

    public static API_DOWNLOAD_HOST_REPORT = Constants.HOST + "/api/hosts/report/";
    public static API_SEND_HOST_REPORT = Constants.API_DOWNLOAD_HOST_REPORT;

    public static API_DOWNLOAD_SCANS_REPORT = Constants.HOST + "/api/scans/report/";
    public static API_SEND_SCANS_REPORT = Constants.API_DOWNLOAD_SCANS_REPORT;

    public static REGEX_IP_VALIDATION = "(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)";

}

