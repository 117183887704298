import { Injectable } from '@angular/core';
import { SmartTableData } from '../data/smart-table';

@Injectable()
export class SmartTableService extends SmartTableData {
  targetsData = [{
    id: 1,
    firstName: 'Mark',
    lastName: 'Otto',
    username: '@mdo',
    email: 'mdo@gmail.com',
    age: '28',
  }, {
    id: 2,
    firstName: 'Jacob',
    lastName: 'Thornton',
    username: '@fat',
    email: 'fat@yandex.ru',
    age: '45',
  }, {
    id: 3,
    firstName: 'Larry',
    lastName: 'Bird',
    username: '@twitter',
    email: 'twitter@outlook.com',
    age: '18',
  }];

  sitesData = [{
    id: 1,
    name: 'MyWebsite',
    url: 'http://mywebsite.local'
  }, {
    id: 2,
    name: 'Website2',
    url: 'http://23mywebsite.local'
  },{
    id: 3,
    name: 'WebsiteTest',
    url: 'http://myw425ebsite.local'
  },{
    id: 4,
    name: 'NewWeb',
    url: 'http://mywebsite112.local'
  }];

  hostsData = [{
    id: 1,
    name: 'Host 102',
    ip: '142.25.36.99'
  }, {
    id: 2,
    name: 'Host Abcd',
    ip: '12.25.66.88'
  }, {
    id: 3,
    name: 'Host 102332',
    ip: '55.88.77.33'
  }, {
    id: 4,
    name: 'Host 012',
    ip: '55.11.44.22'
  }, {
    id: 5,
    name: 'Host 558',
    ip: '10.12.25.36'
  }, {
    id: 6,
    name: 'Host Int',
    ip: '192.168.2.22'
  }, ];
  getData() {
    return this.targetsData;
  }
  getTargetsData() {
    return this.targetsData;
  }  
  getSitesData() {
    return this.sitesData;
  }  
  getHostsData() {
    return this.hostsData;
  }
}
