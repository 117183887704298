<div class="header-container" *ngIf="user.roles[0] != 'TMP_ROLE'">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo" href="#" (click)="navigateHome()"><img src="assets/images/spartandetector_logo_black.png" style="width: 200px; height: 40px;"></a>
  </div>
  <!-- <nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary" style="margin-left:35px;">
    <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>
  </nb-select> -->
</div>

<div class="header-container" *ngIf="user.roles[0] != 'TMP_ROLE'">
  <nb-actions size="small">

    <!-- <nb-action class="control-item">
      <nb-search type="rotate-layout"></nb-search>
    </nb-action>
    <nb-action class="control-item" icon="email-outline"></nb-action>
    <nb-action class="control-item" icon="bell-outline"></nb-action> -->
    <nb-action icon="award-outline"
      badgeDot
      badgePosition="top right"
      badgeStatus="warning"
      nbTooltip="Got to Account's Subscriptions" (click)="goToSubscriptions()"
      >
  </nb-action>
    <nb-action class="user-action" *nbIsGranted="['view', 'user']" >
      <nb-user [nbContextMenu]="userMenu"
               [onlyPicture]="userPictureOnly"
               [name]="user?.email"
               [picture]="user?.picture">
      </nb-user>
    </nb-action>
  </nb-actions>
  <div style="border-color: rgb(250, 243, 243); border-style: solid; padding: 5px; margin-left: 120px;">
    <nb-select status="primary" [selected]="selectedLanguage" style="margin-right: 20px; width: 120px;" (selectedChange)="updateSelectedLanguage($event)">
      <nb-option *ngFor="let lang of allLanguagesKeys" [value]="lang">{{allLanguages[lang]['lang_name']}}</nb-option>
    </nb-select>
    <img src={{langFlag}} style="margin-left: 0px; width: 55px;">
  </div>
</div>
