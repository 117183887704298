import { StatisticsService } from './../../pages/services/statistics.service';
import { constants } from "buffer";
import { Console } from "console";

export class Constants {

    public static HOST = "https://application.spartandetector.ro"
    // public static HOST = "http://localhost:8090";
    //public static HOST = "http://192.168.15.106:8090";
    // public static HOST = "http://spartan-srv.local.com:8090";

    public static SUBSCRIPTIONS_CONTROLLER_URL = Constants.HOST + "/api/subscriptions";
    public static USERS_CONTROLLER_URL = Constants.HOST + "/api/users";
    public static EVENTS_CONTROLLER_URL = Constants.HOST + "/api/events";
    public static STATISTICS_CONTROLLER_URL =Constants.HOST + "/api/statistics";
    public static DASHBOARD_CONTROLLER_URL = Constants.HOST + "/api/dashboard";
    public static SCAN_CONTROLLER_URL = Constants.HOST + "/api/scans";
    public static BANK_ACCOUNT_CONTROLLER_URL = Constants.HOST + "/api/bankAccounts"
    public static AUTH_CONTROLLER_URL = Constants.HOST + "/api/auth";

    public static OAUTH2_URL = Constants.HOST  + "/oauth2/authorization/google";
    //public static OAUTH2_URL = /*Constants.HOST*/ "http://spartan-srv.local.com:8090" + "/oauth2/authorization/google";
    // public static OAUTH2_URL = /*Constants.HOST*/ "http://localhost:8090" + "/oauth2/authorization/google";
   //public static OAUTH2_URL = /*Constants.HOST*/ "https://spartan-srv.local.com" + "/oauth2/authorization/google";

    //public static REDIRECT_URL = "?redirect_uri=http://spartan-srv.local.com/auth/login";
    // public static REDIRECT_URL = "?redirect_uri=http://192.168.15.106/auth/login";
    // public static REDIRECT_URL = "?redirect_uri=http://localhost:4200/auth/login"
    public static REDIRECT_URL = "?redirect_uri=https://application.spartandetector.ro/auth/login"

    public static LOGIN_ENDPOINT = Constants.AUTH_CONTROLLER_URL + "/signin";
    public static REGISTER_ENDPOINT = Constants.AUTH_CONTROLLER_URL + "/signup";
    public static LOGOUT_ENDPOINT =  Constants.AUTH_CONTROLLER_URL + "/logout";
    public static FORGOT_PASS_ENDPOINT = Constants.AUTH_CONTROLLER_URL + "/forgotPassword";
    public static GO0GLE_OAUTH2_URL = Constants.OAUTH2_URL + Constants.REDIRECT_URL;
    public static RESET_PASSWORD_URL= Constants.AUTH_CONTROLLER_URL + "/resetPassword";
    public static RESEND_REGISTRATION_TOKEN_URL = Constants.AUTH_CONTROLLER_URL + "/resendRegistrationToken";
    public static ACTIVATE_ACCOUNT_URL =Constants.AUTH_CONTROLLER_URL + "/confirmRegistration";
    public static CHANGE_PASSWORD_URL = Constants.AUTH_CONTROLLER_URL + "/updatePassword";



    public static GET_SUBSCRIPTION_TYPES_URL = Constants.SUBSCRIPTIONS_CONTROLLER_URL + "/all";
    public static GET_ACTIVE_SUBSCRIPTIONS = Constants.SUBSCRIPTIONS_CONTROLLER_URL + "/allActive";
    public static GET_ACTIVE_SUBSCRIPTIONS_BY_USER =Constants.SUBSCRIPTIONS_CONTROLLER_URL + "/allActive/forUser";
    public static GET_ACTIVE_SUBSCRIPTIONS_FOR_CURRENT_USER = Constants.SUBSCRIPTIONS_CONTROLLER_URL + "/allActiveSubscriptions"
    public static GET_ENABLED_ACTIVE_SUBSCRIPTIONS_FOR_CURRENT_USER = Constants.SUBSCRIPTIONS_CONTROLLER_URL + "/enabledActiveSubscriptions";
    public static ADD_SUBSCRIPTION_URL = Constants.SUBSCRIPTIONS_CONTROLLER_URL + "/add";
    public static UPDATE_SUBSCRIPTION_URL = Constants.SUBSCRIPTIONS_CONTROLLER_URL + "/updateSubscription";
    public static DELETE_SUBSCRIPTION_URL = Constants.SUBSCRIPTIONS_CONTROLLER_URL + "/deleteSubscription";
    public static GET_SIMPLE_SUBSCRIPTION_STATISTICS = Constants.SUBSCRIPTIONS_CONTROLLER_URL + "/simpleStatistics";
    public static UPDATE_ACTIVE_SUBSCRIPTION_TRANSFER = Constants.SUBSCRIPTIONS_CONTROLLER_URL + "/updateSubscriptionTransfer";
    public static GET_ALL_ENABLED_SUBSCRIPTIONS = Constants.SUBSCRIPTIONS_CONTROLLER_URL + "/allEnabled";
    public static CREATE_ACTIVE_SUBSCRIPTION_FOR_TRANSFER = Constants.SUBSCRIPTIONS_CONTROLLER_URL + "/createSubscriptionForTransfer";
    public static UPDATE_ACTIVESUBSCRIPTION_STATUS = Constants.SUBSCRIPTIONS_CONTROLLER_URL + "/updateActiveSubscription";

    public static GET_SUBSCRIPTIONS_WITH_PACKAGES = Constants.SUBSCRIPTIONS_CONTROLLER_URL + "/withPackages";
    public static GET_SUBSCRIPTION_PACKAGES_FOR_SUBSCRIPTION = Constants.SUBSCRIPTIONS_CONTROLLER_URL + "/packages/forSubscription";
    public static ADD_SUBSCRIPTION_PACKAGE = Constants.SUBSCRIPTIONS_CONTROLLER_URL + "/packages/add";
    public static DELETE_SUBSCRIPTION_PACKAGE = Constants.SUBSCRIPTIONS_CONTROLLER_URL + "/packages/delete";
    public static UPDATE_SUBSCRIPTION_PACKAGE =Constants.SUBSCRIPTIONS_CONTROLLER_URL + "/packages/update";
    public static GET_SUBSCRIPTION_PACKAGE_BY_ID = Constants.SUBSCRIPTIONS_CONTROLLER_URL + "/packages/id";

    public static GET_PAID_ACTIVE_SUBSCRIPTIONS_FOR_USER = Constants.SUBSCRIPTIONS_CONTROLLER_URL + "/paidActiveSubscriptions";
    public static GET_PAID_ACTIVE_SUBSCRIPTIONS_WITH_DELEGATED_SUBSCRIPTIONS = Constants.SUBSCRIPTIONS_CONTROLLER_URL +"/paidActiveSubscriptionsWithDelegatedSubscriptions";
    public static CREATE_NEW_TEAM_MEMBER_ACCOUNT = Constants.SUBSCRIPTIONS_CONTROLLER_URL + "/delegated/create";
    public static EDIT_TEAM_MEMBER_DELEGATED_SUBSCRIPTION = Constants.SUBSCRIPTIONS_CONTROLLER_URL + "/delegated/edit";
    public static EDIT_TEAM_MEMBER_DELEGATED_SUBSCRIPTION_BY_ADMIN = Constants.SUBSCRIPTIONS_CONTROLLER_URL + "/delegated/admin/edit";
    public static DELETE_TEAM_MEMBER_DELEGATEDsUBSCRIPTION = Constants.SUBSCRIPTIONS_CONTROLLER_URL + "/delegated/delete";
    public static GET_DELEGATED_ACTIVE_SUBSCRIPTION_FOR_USER = Constants.SUBSCRIPTIONS_CONTROLLER_URL + "/delegatedSubscription";
    public static GET_DELEGATED_ACTIVESUBSCRIPTIONS_FOR_LOCAL_ADMIN = Constants.SUBSCRIPTIONS_CONTROLLER_URL + "/delegatedSubscriptions/forUser";


    public static MARK_ACTIVE_SUBSCRIPTION_AS_PAID = Constants.SUBSCRIPTIONS_CONTROLLER_URL + "/active/markAsPaid";


    public static GET_ALL_ACCOUNTS = Constants.BANK_ACCOUNT_CONTROLLER_URL + "/all";
    public static GET_ALL_ENABLED_ACCOUNTS = Constants.BANK_ACCOUNT_CONTROLLER_URL + "/allEnabled";
    public static CREATE_NEW_ACCOUNT = Constants.BANK_ACCOUNT_CONTROLLER_URL + "/add";
    public static UPDATE_ACCOUNT = Constants.BANK_ACCOUNT_CONTROLLER_URL + "/update";
    public static DELETE_ACCOUNT = Constants.BANK_ACCOUNT_CONTROLLER_URL + "/delete";



    public static GET_ALLUSERS_URL = Constants.USERS_CONTROLLER_URL + "/all";
    public static GET_USER_BY_ID = Constants.USERS_CONTROLLER_URL + "/details";
    public static UPDATE_USER = Constants.USERS_CONTROLLER_URL + "/updateUserStatus";
    public static GET_CURRENT_USER_DETAILS = Constants.USERS_CONTROLLER_URL + "/current";






    public static GET_EVENTS_FOR_USER = Constants.EVENTS_CONTROLLER_URL + "/all/ForUser";
    public static GET_EVENTS_FOR_CURRENT_USER = Constants.EVENTS_CONTROLLER_URL + "/dashboard";

    public static GET_USERSTATISTICS = Constants.DASHBOARD_CONTROLLER_URL + "/id";

    public static GET_STATISTICS = Constants.STATISTICS_CONTROLLER_URL + "/dashboard";


    public static GET_MONTHLY_SCAN_STATISTICS = Constants.SCAN_CONTROLLER_URL + "/monthlyStatistics";


    public static VPN_CONTROLLER_URL = Constants.HOST + "/api/vpn";
    public static GET_VPN_PROFILES = Constants.VPN_CONTROLLER_URL + "/my-profiles";

}

