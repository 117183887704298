import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';

import { UserData } from '../../../@core/data/users';
import { LayoutService } from '../../../@core/utils';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TokenStorageService } from '../../../auth/services/token-storage.service';
import { Router } from '@angular/router';
import langItems from '../../../../assets/data/lang.json';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'default';

  userMenu = [ ];
  langFlag = "./assets/images/en_flag.png";
  selectedLanguage = "en";
  
  allLanguages = langItems;
  allLanguagesKeys = Object.keys(langItems);
  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private themeService: NbThemeService,
              private userService: UserData,
              private layoutService: LayoutService,
              private breakpointService: NbMediaBreakpointsService,
              private tokenService: TokenStorageService,
              private router: Router) {

        this.user = this.tokenService.getUser();
        let acccountLink  = "" ;
        if (this.user.roles[0] === "ADMIN_ROLE")
            acccountLink = "/admin/account";  
        else 
            acccountLink = "/pages/user-account/all";
        this. userMenu =[ { title: 'Profile & Settings', link: acccountLink }, { title: 'Log out', action:'' }];
  }

  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;

    /*this.userService.getUsers()
      .pipe(takeUntil(this.destroy$))
      .subscribe((users: any) => this.user = users.nick);*/

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);

      if(!sessionStorage.getItem("lang"))
      {
        sessionStorage.setItem("lang", "en");
      }
      
      this.selectedLanguage = sessionStorage.getItem("lang");
      this.langFlag = "./assets/images/" + this.selectedLanguage + "_flag.png";
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  goToSubscriptions()
  {
    this.router.navigate(["/pages/user-account/assigned-subscriptions"]);
  }


  updateSelectedLanguage(event)
  {
    sessionStorage.setItem("lang", event);
     
    window.location.reload();
  }
}
