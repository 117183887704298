import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Constants } from "../../admin/utils/constants";
import { ChangePasswordRequest } from "../models/changePasswordRequest";
import { ForgotPasswordRequest } from "../models/forgot-PasswordRequest";
import { LoginRequest } from "../models/loginRequest";
import { PasswordResetRequest } from "../models/passwordResetRequest";
import { RegistrationRequest } from "../models/registrationRequest";

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

@Injectable({
    providedIn: 'root'
  })
  export class AuthService {
    constructor(private http: HttpClient) { }
  
   
    register(request: RegistrationRequest): Observable<any> {
      return this.http.post(Constants.REGISTER_ENDPOINT , request, httpOptions);
    }

    login(request: LoginRequest): Observable<any> {
      return this.http.post(Constants.LOGIN_ENDPOINT , request, httpOptions);
    }

    forgotPassword(request: ForgotPasswordRequest ):  Observable<any> {
      return this.http.post(Constants.FORGOT_PASS_ENDPOINT , request, httpOptions);
    }

    resetPassword(request: PasswordResetRequest): Observable<any> {
      return this.http.post(Constants.RESET_PASSWORD_URL , request, httpOptions);
    }

    resendRegistrationToken(request: ForgotPasswordRequest): Observable<any> {
      return this.http.post(Constants.RESEND_REGISTRATION_TOKEN_URL , request, httpOptions);
    }


    activateAccount(token: string): Observable<any> {
      return this.http.get(Constants.ACTIVATE_ACCOUNT_URL + "?token=" + token, httpOptions);
    }

    signout(): Observable<any> {
      return this.http.get(Constants.LOGOUT_ENDPOINT, httpOptions);
    }

    changePassword(request: ChangePasswordRequest) : Observable<any> {
      return this.http.post(Constants.CHANGE_PASSWORD_URL, request, httpOptions)
    }
  

    
  }