import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { retry } from 'rxjs/operators';
import { Constants } from '../utils/constants';

@Injectable({ providedIn: 'root' })
export class StatisticsService {

    private currentUserSubject: BehaviorSubject<any>;
    public currentUser: Observable<any>;

    constructor(private http: HttpClient, private router: Router) {
      this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser')));
      this.currentUser = this.currentUserSubject.asObservable();
    }

    create_header() {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json'        
        })
      };
      return httpOptions;
    }

    public getDashboardStatistics()
    {
      const httpOptions = this.create_header();
      return this.http.get<any>(Constants.API_STATISTICS_DASHBOARD, httpOptions);
    }
}
