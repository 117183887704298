import { Injectable } from '@angular/core';
import { of as observableOf,  Observable } from 'rxjs';
import { StatisticsService } from '../../pages/services/statistics.service';
import { ProfitBarAnimationChartData } from '../data/profit-bar-animation-chart';

@Injectable()
export class ProfitBarAnimationChartService extends ProfitBarAnimationChartData {

  private data: any;

  constructor(private statisticsService: StatisticsService) {
    super(); 
    

    // this.data = {
    //   firstLine: this.getDataForFirstLine(),
    //   secondLine: this.getDataForSecondLine(),
    // };   
  }

  getDataForFirstLine(): number[] {
    // return this.createEmptyArray(12)
    //   .map((_, index) => {
    //     const oneFifth = index / 5;

    //     return (Math.sin(oneFifth)*0.5 + index / 6) * 5;
    //   });
    return [0,0,0,0,0,0,0,0,0,0,0,156];
  }

  getDataForSecondLine(): number[] {
    // return this.createEmptyArray(12)
    //   .map((_, index) => {
    //     const oneFifth = index / 5;

    //     return (Math.cos(oneFifth)*1.5  + index / 6) * 5;
    //   });
    
    return [0,0,0,0,0,0,0,0,0,0,0,3];
  }

  createEmptyArray(nPoints: number) {
    return Array.from(Array(nPoints));
  }

  getChartData(){
    return this.statisticsService.getDashboardStatistics();
  }

  async getData()
  {
    await this.statisticsService.getDashboardStatistics().subscribe(statisticsData => {
      let firstLine = [];
      let secondLine = [];

      statisticsData.scansIssuesMonthly.forEach(el => {
        firstLine.push(el.issuesNumber);
        secondLine.push(el.scansNumber);
      });

      this.data.firstLine = firstLine;
      this.data.secondLine = secondLine;

    });
  }
}
