import { HTTP_INTERCEPTORS, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

import {tap} from 'rxjs/operators';
import { Observable } from 'rxjs';
import { TokenStorageService } from './services/token-storage.service';
 
const TOKEN_HEADER_KEY = 'Authorization';
 
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private tokenStorage: TokenStorageService, private router: Router) {
 
    }
 
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let authReq = req;
        const loginPath = '/auth/login';
        const token = this.tokenStorage.getToken();
        if (token != null) {
            authReq = req.clone({ headers: req.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token) });
        }
        return next.handle(authReq).pipe( tap(() => {},
        (err: any) => {
            if (err instanceof HttpErrorResponse) {
                if (err.status !== 401 || window.location.pathname === loginPath) {
                    return;
                }
                this.tokenStorage.signOut();
                window.location.href = loginPath;
            }
        }
        ));
    }
}
 
export const authInterceptorProviders = [
{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
];