import { Injectable } from '@angular/core';
import { of as observableOf,  Observable } from 'rxjs';
import { PeriodsService } from './periods.service';
import { TrafficList, TrafficListData } from '../data/traffic-list';

@Injectable()
export class TrafficListService extends TrafficListData {

  private getRandom = (roundTo: number) => Math.round(Math.random() * roundTo);
  private data = {};

  constructor(private period: PeriodsService) {
    super();
    this.data = {
      week: this.getDataWeek(),
      month: this.getDataMonth(),
      year: this.getDataYear(),
    };
  }

  private getDataWeek(): TrafficList[] {
    const getFirstDateInPeriod = () => {
      const weeks = this.period.getWeeks();

      return weeks[weeks.length - 1];
    };

    return this.reduceData(this.period.getWeeks(), getFirstDateInPeriod);
  }

  private getDataMonth(): TrafficList[] {
    const getFirstDateInPeriod = () => {
      const months = this.period.getMonths();

      return months[months.length - 1];
    };

    return this.reduceData(this.period.getMonths(), getFirstDateInPeriod);
  }

  private getDataYear(): TrafficList[] {
    const getFirstDateInPeriod = () => {
      const years = this.period.getYears();

      return `${parseInt(years[0], 10) - 1}`;
    };

    return this.reduceData(this.period.getYears(), getFirstDateInPeriod);
  }

  private reduceData(timePeriodss: string[], getFirstDateInPeriod: () => string): TrafficList[] {
    let timePeriods: string[] = ["Site0", "Site1", "Host0", "Site2", "Site4", "Host1", "Host2", "Host3", "Host4", "Site5"];
    let prevValues: number[] = [15, 0, 12, 16, 8, 2, 22, 14, 5, 3];
    let prevDates: string[] = ["23.06 2021", "17.06 2021", "13.06 2021", "30.06 2021", "16.06 2021", "22.06 2021", "23.06 2021", "15.06 2021", "12.06 2021", "3.06 2021", ];
    let nextDates: string[] = ["01.07 2021", "02.07 2021", "02.07 2021", "03.07 2021", "05.07 2021", "05.07 2021", "07.07 2021", "07.07 2021", "07.07 2021", "07.07 2021", ];

    return timePeriods.reduce((result, timePeriod, index) => {
      const prevDate = prevDates[index];
      const prevValue = prevValues[index];
      const nextValue = this.getRandom(50);
      const nextDate = nextDates[index];
      const deltaValue = prevValue - nextValue;

      const item = {
        date: timePeriod,
        value: this.getRandom(50),
        delta: {
          up: deltaValue <= 0,
          value: Math.abs(deltaValue),
        },
        comparison: {
          prevDate,
          prevValue,
          nextDate,
          nextValue,
        },
      };

      return [...result, item];
    }, []);
  }

  getTrafficListData(period: string): Observable<TrafficList> {
    return observableOf(this.data[period]);
  }
}
